import React from 'react';

import SignUpBanner from '../components/Banners/SignUp';
import { PrimaryButton } from '../components/Buttons/PrimaryButton';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { Hero } from '../components/InnerPages/Hero';
import { IInputOnChange, Input } from '../components/Inputs/Input';
import { ITextareaOnChange, Textarea } from '../components/Inputs/Textarea';
import { GatsbyLink } from '../components/Links/GatsbyLink';
import FacebookIcon from '../images/Facebook';
import InstagramIcon from '../images/Instagram';
import PinterestIcon from '../images/Pinterest';
import TwitterIcon from '../images/Twitter';

import style from '../styles/pages/contact.scss';

import axios from 'axios';
import cn from 'classnames';
import { isEmail } from 'utils/helpers';

interface ISignUpBannerProps {
  className?: string;
  btnText?: string;
  location: any;
}

interface IPayload {
  [name: string]: string;
}

interface IInvalidFields {
  [name: string]: boolean;
}

interface ISignUpBannerState {
  payload: IPayload;
  invalidFields: IInvalidFields;
  errorMsg: string;
  isSending: boolean;
  isSubmitted: boolean;
}

const requiredFields: string[] = ['name', 'email', 'message'];

class Contact extends React.Component<ISignUpBannerProps, ISignUpBannerState> {
  constructor(props: ISignUpBannerProps) {
    super(props);
    this.state = {
      // request payload
      errorMsg: '',
      invalidFields: {},
      isSending: false,
      isSubmitted: false,
      payload: {},
    };
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isSending, payload } = this.state;
    if (isSending) {
      return;
    }

    // Validate fields
    const invalidFields: IInvalidFields = requiredFields.reduce(
      (o, name: string) => ({ ...o, [name]: !payload[name] }),
      {}
    );
    if (requiredFields.some((name) => invalidFields[name])) {
      this.setState({ invalidFields, errorMsg: 'All fields are required.' });
      return;
    }
    if (!isEmail(payload.email)) {
      this.setState({ invalidFields: { email: true }, errorMsg: 'Invalid email.' });
      return;
    }

    this.setState({ isSending: true, errorMsg: '' });

    const url = process.env.CONTACT_US_ENDPOINT || '';

    axios
      .post(url, payload)
      .then((res) => {
        this.setState({ isSubmitted: true, isSending: false, payload: {} });
        setTimeout(() => {
          this.setState({ isSubmitted: false });
        }, 2000);
      })
      .catch((err) => {
        this.setState({ errorMsg: err.message, isSending: false });
      });
  };

  handleChange = (input: IInputOnChange | ITextareaOnChange) => {
    const { payload, invalidFields } = this.state;
    this.setState({
      invalidFields: { ...invalidFields, [input.name]: false },
      payload: { ...payload, [input.name]: input.value },
    });
  };

  render() {
    const { location, btnText } = this.props;
    const { errorMsg, invalidFields, isSubmitted, isSending } = this.state;
    const { name = '', email = '', message = '' } = this.state.payload;

    return (
      <>
        <MetaData data={{}} location={location} title="Contact" />
        <Layout headerClassName={style.header} mainClass={style.main}>
          <Hero
            title="Contact us"
            description="Are you on a path to gain independence? Feel free to contact us and share your wisdom, concerns, and experiences. Want to contribute to or partner? We’re always looking for  <a href='/write-partner'>writers and sponsors</a>."
          />

          <div className={style.content}>
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                  <h3 className="d-none">Send us note:</h3>
                  <form className="d-none" onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <Input
                          value={name}
                          name="name"
                          className={style.inputWrapper}
                          onChange={this.handleChange}
                          placeholder="Your Name"
                          helperText={errorMsg}
                          hasError={!!errorMsg && invalidFields.name}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <Input
                          value={email}
                          name="email"
                          className={style.inputWrapper}
                          onChange={this.handleChange}
                          placeholder="Email Adress"
                          helperText={errorMsg}
                          hasError={!!errorMsg && invalidFields.email}
                        />
                      </div>
                      <div className="col-12">
                        <Textarea
                          value={message}
                          name="message"
                          className={style.inputWrapper}
                          onChange={this.handleChange}
                          placeholder="Your Message"
                          helperText={errorMsg}
                          hasError={!!errorMsg && invalidFields.message}
                        />
                      </div>
                      <div className="col-12">
                        <PrimaryButton type="submit" isLoading={isSending}>
                          {btnText || 'Submit'}
                        </PrimaryButton>
                        {isSubmitted ? <span className={style.statusText}>Sent!</span> : null}
                        {errorMsg && !requiredFields.some((name) => invalidFields[name]) ? (
                          <span className={cn(style.statusText, style.error)}>{errorMsg}</span>
                        ) : null}
                      </div>
                    </div>
                  </form>
                  <p className={style.tempText}>
                    Send a note to{' '}
                    <a href="mailto:hello@thinksaveretire.com">hello@thinksaveretire.com</a>
                  </p>
                  {/* <h3>You may also contact us through social media:</h3> */}
                  <h3>Follow @ThinkSaveRetire on your favorite social platform:</h3>
                  <div className={cn('col-12', style.socialLinks)}>
                    <a
                      href="https://www.facebook.com/thinksaveretire"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookIcon />
                    </a>
                    <a
                      href="https://twitter.com/thinksaveretire"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TwitterIcon />
                    </a>
                    <a
                      href="https://www.instagram.com/thinksaveretire/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon />
                    </a>
                    <a
                      href="https://www.pinterest.com/thinksaveretire/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <PinterestIcon />
                    </a>
                  </div>
                  <p>
                    If you are wondering whether or not Think Save Retire accepts guest posts, we do
                    — <GatsbyLink to="/guest-and-sponsored-posts/">click here</GatsbyLink>.
                    Remember! Have a topic in mind and pitch it. Check out this{' '}
                    <GatsbyLink to="/ask-guest-post-blog-get-every-time/">guest post</GatsbyLink>{' '}
                    about how to pitch your guest post idea.
                  </p>
                  <p>
                    If you are looking for more information regarding sponsored posts / post-based
                    ads, we accept those as well —{' '}
                    <GatsbyLink to="/sponsored-posts/">click here</GatsbyLink>.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <SignUpBanner />
        </Layout>
      </>
    );
  }
}

export default Contact;
